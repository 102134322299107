/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * The current setup is for "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/guide/browser-support
 */

/***************************************************************************************************
 * BROWSER POLYFILLS
 */

// Polyfills for ECMAScript 2015 (ES6) features.
import 'core-js/es/array';
import 'core-js/es/date';
import 'core-js/es/function';
import 'core-js/es/map';
import 'core-js/es/math';
import 'core-js/es/number';
import 'core-js/es/object';
import 'core-js/es/promise';
import 'core-js/es/regexp';
import 'core-js/es/set';
import 'core-js/es/string';
import 'core-js/es/symbol';
import 'core-js/es/weak-map';

// Polyfills for ECMAScript 2016+ features.
// Si 'core-js/es/next' no está disponible, puedes comentar esta línea o eliminarla
// import 'core-js/es/next';

// Polyfills for web animations, required by Angular.
import 'web-animations-js'; // Si no necesitas este polyfill, puedes comentarlo o eliminarlo

import 'zone.js';  // Included with Angular CLI.

/***************************************************************************************************
 * APPLICATION IMPORTS
 */

/**
 * Add global to window, assigning the value of global as window for some libraries.
 */
// @ts-ignore
(window as any).global = window;